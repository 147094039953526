<template>
  <div
    :class="[
      'multiview-sort-button',
      { 'multiview-sort-button--is-asc': sort === 'STARTS_AT_ASC' },
      { 'multiview-sort-button--is-desc': sort === 'STARTS_AT_DESC' },
    ]"
    @click="switchEventsOrder"
  >
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          class="multiview-sort-button__arrow-up"
          d="M11.0263 2.55279C11.4416 1.81574 12.5584 1.81574 12.9737 2.55279L16.8688 9.46554C17.2605 10.1607 16.7279 11 15.8951 11H8.10492C7.27209 11 6.73948 10.1607 7.13118 9.46554L11.0263 2.55279Z"
        />
        <path
          class="multiview-sort-button__arrow-down"
          d="M12.9737 21.4472C12.5584 22.1843 11.4416 22.1843 11.0263 21.4472L7.13118 14.5345C6.73948 13.8393 7.27209 13 8.10492 13L15.8951 13C16.7279 13 17.2605 13.8393 16.8688 14.5345L12.9737 21.4472Z"
        />
      </g>
    </svg>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import {
  includes,
  indexOf,
  pickBy,
  identity,
  cloneDeep,
} from 'lodash';

export default {
  setup() {
    const route = useRoute();
    const router = useRouter();

    const sortOptions = computed(() => ['STARTS_AT_ASC', 'STARTS_AT_DESC', 'NATURAL']);
    const sort = computed(() => {
      const querySort = route.query.sort;
      if (!includes(sortOptions.value, querySort)) return sortOptions.value[0];
      return querySort;
    });
    const switchEventsOrder = () => {
      let sortIndex = indexOf(sortOptions.value, sort.value);
      if (sortIndex === -1 || sortIndex + 1 >= sortOptions.value.length) sortIndex = -1;
      const nextSort = sortOptions.value[sortIndex + 1];

      const query = cloneDeep(route.query);
      if (nextSort !== sortOptions.value[0]) {
        query.sort = nextSort;
      } else {
        delete query.sort;
      }

      router.replace({
        name: route.name,
        query: pickBy(query, identity),
      });
    };

    return {
      sort,
      switchEventsOrder,
    };
  },
};
</script>

<style lang="scss">
.multiview-sort-button {
  cursor: pointer;
  width: 16px;
  height: 16px;
  margin-right: 2px;

  &__arrow-up,
  &__arrow-down {
    fill: #CDCDCD;
  }

  &--is-asc {
    .multiview-sort-button__arrow-up {
      fill: #191414;
    }

    .multiview-sort-button__arrow-down {
      fill: #CDCDCD;
    }
  }

  &--is-desc {
    .multiview-sort-button__arrow-up {
      fill: #CDCDCD;
    }

    .multiview-sort-button__arrow-down {
      fill: #191414;
    }
  }
}
</style>
